<template>
    <span v-show="show" class="flip-clock__piece">
        <span class="flip-clock__card flip-card">
            <b class="flip-card__top">{{ formatValue(current) }}</b>
            <b class="flip-card__bottom" :data-value="formatValue(current)"></b>
            <b class="flip-card__back" :data-value="formatValue(previous)"></b>
            <b class="flip-card__back-bottom" :data-value="formatValue(previous)"></b>
        </span>
    </span>
</template>
<script>
export default {
    props: ['property', 'time'],
    data: () => ({
        current: 0,
        previous: 0,
        show: false
    }),
    watch: {
        time(newValue) {
            if (newValue[this.property] === undefined) {
                this.show = false;
                return;
            }

            var val = newValue[this.property];
            this.show = true;

            val = (val < 0 ? 0 : val);

            if (val !== this.current) {

                this.previous = this.current;
                this.current = val;

                this.$el.classList.remove('flip');
                void this.$el.offsetWidth;
                this.$el.classList.add('flip');
            }

        }
    },
    methods: {
        formatValue(value) {
            return (value < 10 && value > -1 ? '0' : '') + value;
        },
    },
}
</script>