<template>
    <div class="b2c-page-bg">
        <!-- <div class="bg-change"></div> -->
        <div class="fs-inr banner-inr" id="bannerSim">
            <div class="banner-slider swiper-container">
                <swiper :slides-per-view="1" :space-between="50">
                    <swiper-slide>
                        <a class="fs-pic" href="/mua-sim-so-dep"><img loading="lazy" alt="Banner Mobile" class="fs-sp"
                                src="../assets/images/sim-so-dep/banner-sim-so-dep1.png" /></a>
                        <a class="fs-pic desktop-img" href="/mua-sim-so-dep"><img loading="lazy" alt="Banner Desktop"
                                class="fs-pc" src="../assets/images/sim-so-dep/banner-sim-so-dep1.png" /></a>
                    </swiper-slide>
                    <swiper-slide>
                        <a class="fs-pic" href="/mua-sim-so-dep"><img loading="lazy" alt="Banner Mobile" class="fs-sp"
                                src="../assets/images/sim-so-dep/banner-sim-so-dep2.png" /></a>
                        <a class="fs-pic desktop-img" href="/mua-sim-so-dep"><img loading="lazy" alt="Banner Desktop"
                                class="fs-pc" src="../assets/images/sim-so-dep/banner-sim-so-dep2.png" /></a>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="fs-pc">
            <div class="list-sim-container red-bg__important">
                <div class="header-sticky" id="headerSticky">
                    <div class="b2c-pc-find mr-auto ml-auto">
                        <p class="leading-required-number">Nhập số thuê bao mong muốn</p>
                        <div>
                            <div>
                                <div class="b2c-pc-number-087">087</div>
                                <div class="b2c-pc-number-msisdn">
                                    <input-required-phone v-model.trim="SearchString" @onSearch="handleSearch()" />
                                </div>
                                <div class="b2c-number-btn">
                                    <button @click="handleSearch()"></button>
                                </div>
                            </div>
                            <div :class="numberExceptCollapse ? 'b2c-pc-number-except' : 'b2c-pc-number-except-e'"
                                style="margin-left: 155px;">
                                <div class="b2c-pc-number-except-text">Loại trừ số:</div>
                                <div class="b2c-pc-number-except-buttons">
                                    <button @click="exception(i - 1)" v-for="i in [5, 8, 1, 2, 3, 4, 6, 7, 9, 10]" :key="i"
                                        :class="serverParams.columnFilters.except.find(j => j == i - 1) == undefined ? 'b2c-number-except-btn' : 'b2c-number-except-btn-checked'">
                                        {{ i - 1 }}
                                    </button>
                                </div>
                                <button @click="numberExceptCollapse = !numberExceptCollapse"
                                    :class="numberExceptCollapse ? 'b2c-number-except-colapse' : 'b2c-number-except-nocolapse'" />
                            </div>
                        </div>
                    </div>

                    <div class="search mt-2 mt-lg-0 d-flex align-items-center justify-content-center">
                        <!-- <h5 class="most-find-on-month-sp" style="font-size: 18px !important;">Tìm kiếm nhiều trong tháng
                        </h5> -->
                        <div class="search__item justify-content-center">
                            <button @click="handlesearch(item.Id, item.simType)" v-for="(item, index) of dataSearchMonthly"
                                class="b2c-buy-btn search_for_month" :key="item.Id"
                                :class="isClick && isClick === item.Id ? 'red-bg' : ''">
                                {{ item.Name }}
                            </button>
                        </div>
                    </div>

                </div>
                <div class="b2c-pc-find-sub d-flex pb-1 pt-2 w-100">
                    <div class="b2c-pagination-title" style="width: max-content;" v-if="isNotFound">!!! Rất tiếc, số bạn
                        đang tìm
                        hiện không còn. Có thể bạn sẽ thích những số sau:
                    </div>
                    <div v-else class="b2c-pagination-title" style="width: max-content;">{{ totalRecords | number }} số hiện
                        có
                    </div>
                    <select @change="serverParams.page = 1" v-model="serverParams.extra.price_sort"
                        class="b2c-pagination-title-sort">
                        <option v-for="i in prices" :key="i.id" :value="i.id">{{ i.text }}</option>
                    </select>
                </div>
                <div class="b2c-pc-list-container">
                    <div class="b2c-pc-list" id="b2cPcList">
                        <table>
                            <thead>
                                <tr>
                                    <th style="text-align:center;width:15%">Số điện thoại</th>
                                    <th style="width:20%" class="pl-1">Loại số</th>
                                    <th class="pl-1">Gói cước / Chính sách</th>
                                    <th style="text-align:right;width:140px; padding-right: 10px">Giá tiền</th>
                                    <th style="width:160px"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="i in rows" :key="i.Phone"
                                    :class="cart.find(j => j.Phone == i.Phone) != undefined ? 'b2c-bg-selected' : ''">
                                    <td style="text-align:center" :product_link="`https://itel.vn/san-pham/${i.Phone}`">
                                        <span class="b2c-phone" v-html="i.PhoneFormated"></span>
                                    </td>
                                    <td style="text-align:left">
                                        <span class="badge badge-primary mr-1 b2c-badge" @click="handleSimVip()"
                                            style="padding: 5px 5px;" v-if="i.Price > 5000000">SỐ VIP</span>
                                        <span class="badge badge-primary mr-1 b2c-badge" v-if="i.ThoiGianCamKet > 0"
                                            style="padding: 5px 5px;" @click="handlePledge()">Số cam kết</span>
                                        <span class="badge badge-primary b2c-badge cursor-pointer mr-1"
                                            style="padding: 5px 5px;" @click="handleTypeNumber(i.SimType)">{{ i.group_name
                                            }}</span>
                                        <span @click="handleTypeTags(item)" v-for="(item, index) of i.Tags" :key="index"
                                            style="padding: 5px 5px;"
                                            class="badge badge-primary b2c-badge cursor-pointer mt-1"
                                            :class="index !== i.Tags ? 'mr-1' : ''">{{ item.Tag }}</span>
                                    </td>
                                    <td style="font-size:14px;text-align:left" v-if="i.ThoiGianCamKet > 0">
                                    <div v-if="i.CommitType == 'PSC'" class="b2c-des-highlight">
                                        <div class="cs-desc">Cam kết tiêu dùng tài khoản tối thiểu <span class="bold-text">{{ formatNumber(i.CommitValue) || "49.000" }}đ/tháng </span>trong <span class="bold-text">{{ i.Months || 36 }} tháng</span></div>
                                        <div class="cs-desc_sub">Tiêu dùng tài khoản gồm: đăng ký gói hoặc gọi thoại/SMS/Data</div>
                                    </div>
                                    <div v-else class="b2c-des-highlight">
                                        Cam kết gói <b>{{ i.Pack }} {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ</b> trong <b>{{ i.Months }} </b> tháng <b class="text-line-through">({{ i.Months + 12 }} tháng)</b>
                                        <p style="font-size:12px;margin-top:10px" v-html="PackInfo[i.Pack]" />
                                    </div>
                                    </td>
                                    <td style="font-size:14px;text-align:left;line-height: 120%;margin-top:10px"
                                        v-else-if="$route.query.sim_category_id_sort && $route.query.sim_category_id_sort == 49"
                                        class="b2c-des-highlight">
                                        Gói <b>{{ i.Pack }}</b> - <span v-html="PromotionInfo[i.Pack].flow"></span>
                                        <p style="font-size:12px;margin-top:10px"
                                            v-html="PromotionInfo[i.Pack].extraText" />
                                    </td>
                                    <td style="font-size:14px;text-align:left;" class="b2c-des-highlight" v-else>
                                        Được đăng ký Gói cước <b>{{ i.Pack }}
                                            {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ </b>siêu ưu đãi
                                        <p style="font-size:12px;margin-top:10px" v-html="PackInfo[i.Pack]" />
                                    </td>
                                    <td class="text-right">
                                        <span style="font-size: 17.5px;">{{ i.Price + i.SimPrice | number }}đ</span> <br />
                                        <span class="b2c-ghtk-fee"
                                            style="color: #5e5873; font-weight: 400; padding-right: 0px; font-size: 14px">{{
                                                i.Price + i.SimPrice + 160000 | number }}đ</span>
                                    </td>
                                    <td>
                                        <button v-if="cart.find(j => j.Phone == i.Phone) == undefined" @click="addToCart(i)"
                                            style="border-radius: 25px;" class="b2c-buy-btn">
                                            Mua ngay
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <b-col cols="12" v-if="totalRecords > rows.length">
                                <b-pagination class="float-right pt-1 pb-3" v-model="serverParams.page"
                                    :total-rows="totalRecords" :per-page="serverParams.pageSize" first-number
                                    last-number></b-pagination>
                            </b-col>
                        </div>
                    </div>
                    <div class="text-center d-flex justify-content-center" v-if="isLoading">
                        <b-spinner variant="danger"></b-spinner>
                    </div>
                </div>
            </div>
        </div>
        <div class="fs-sp">
            <div class="list-sim-container">
                <div class="header-sticky pb-1" id="headerStickySp">

                    <div class="b2c-pc-find mr-auto ml-auto">
                        <p class="leading-required-number">Nhập số thuê bao mong muốn</p>
                        <div class="mb-find" style="justify-content: space-between;">
                            <div class="container-087">
                                <div class="b2c-pc-number-087">087</div>
                                <div class="b2c-pc-number-msisdn">
                                    <input-required-phone v-model.trim="SearchString" @onSearch="handleSearch()" />
                                </div>
                                <div class="b2c-number-btn">
                                    <button @click="handleSearch()"></button>
                                </div>
                            </div>
                            <div :class="numberExceptCollapse ? 'b2c-pc-number-except' : 'b2c-pc-number-except-e'">
                                <div class="b2c-pc-number-except-text">Loại trừ số:</div>
                                <div class="b2c-pc-number-except-buttons">
                                    <button @click="exception(i - 1)" v-for="i in [5, 8, 1, 2, 3, 4, 6, 7, 9, 10]" :key="i"
                                        :class="serverParams.columnFilters.except.find(j => j == i - 1) == undefined ? 'b2c-number-except-btn' : 'b2c-number-except-btn-checked'">
                                        {{ i - 1 }}
                                    </button>
                                </div>
                                <button @click="numberExceptCollapse = !numberExceptCollapse"
                                    :class="numberExceptCollapse ? 'b2c-number-except-colapse' : 'b2c-number-except-nocolapse'" />
                            </div>
                        </div>
                    </div>
                    <div class="search-for-month-container">
                        <div class="search pr-2 pl-2 mt-2 mt-lg-0 d-flex align-items-center justify-content-center">
                            <!--<h5 class="most-find-on-month-sp">Tìm kiếm nhiều trong tháng</h5>-->
                            <div class="search__item justify-content-center">
                                <button @click="handlesearch(item.Id, item.simType)"
                                    v-for="(item, index) of dataSearchMonthly" class="b2c-buy-btn search_for_month"
                                    :key="item.Id" :class="isClick && isClick === item.Id ? 'red-bg' : ''">
                                    {{ item.Name }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex pb-0 pr-2 pl-2">
                    <div class="b2c-pagination-title"
                        style="width: 70%; text-align: justify; padding-right: 15px; font-size: 12px;" v-if="isNotFound">!! Rất
                        tiếc, số bạn đang tìm hiện không còn. Có thể bạn sẽ thích những số sau:
                    </div>
                    <div v-else class="b2c-pagination-title" style="width: 70%;">{{ totalRecords | number }} số hiện có</div>
                </div> -->
                <div class="b2c-sp-list-container">
                    <div class="b2c-pc-find-sub d-flex pb-1 pt-2" style="padding-inline: 15px">
                        <div class="b2c-pagination-title" style="width: max-content; font-size: 15px" v-if="isNotFound">!!! Rất tiếc, số bạn đang tìm
                            hiện không còn. Có thể bạn sẽ thích những số sau:
                        </div>
                        <div v-else class="b2c-pagination-title" style="width: max-content;">{{ totalRecords | number }} số hiện có
                        </div>
                        <select @change="serverParams.page = 1" v-model="serverParams.extra.price_sort"
                                class="b2c-pagination-title-sort" style="text-align: left;">
                            <option v-for="i in prices" :key="i.id" :value="i.id">{{ i.text }}</option>
                        </select>
                    </div>
                    <div class="w-100 pb-2 pr-2 pl-2 pt-0 b2c-sp-list" id="b2cSpList">
                        <b-card
                            :class="cart.find(j => j.Phone == i.Phone) != undefined ? 'p-0 b2c-bg-selected mt-1' : 'p-0 mt-1'"
                            v-for="i in rows" :key="i.Phone">
                            <b-card-text>
                                <div :product_link="`https://itel.vn/san-pham/${i.Phone}`" class="row">
                                    <div :product_link="`https://itel.vn/san-pham/${i.Phone}`" style="padding-bottom:5px"
                                        class="col-8 b2c-page-msisdn">
                                        <span class="b2c-phone phone-text-mobile" v-html="i.PhoneFormated"></span>
                                    </div>
                                    <div class="col-4 text-right">
                                            <div class="price-text-mobile">
                                        <p class="b2c-price-text"><b>{{ i.Price + i.SimPrice | number }}đ</b></p>
                                        <!-- <p class="b2c-price-text b2c-ghtk-fee"
                                            style="font-size: 16px; color: #5e5873; font-weight: 400; padding-right: 8px; padding-top: 0;">
                                            {{ (i.Price + i.SimPrice + 160000) | number }}đ</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="i.ThoiGianCamKet > 0">
                                    <div v-if="(i.CommitType == 'PSC')" class="col-12 b2c-camket-text b2c-des-highlight mt-0">
                                        <div class="cs-desc" style="max-width: 75%;">Cam kết tiêu dùng tài khoản tối thiểu <span class="bold-text">{{ formatNumber(i.CommitValue) || "49.000" }}đ</span><span class="bold-text">/</span><span class="bold-text">tháng </span>trong <span class="bold-text">{{ i.Months || 36 }} tháng</span></div>
                                        <div class="cs-desc_sub">Tiêu dùng tài khoản gồm: đăng ký gói hoặc gọi thoại/SMS/Data</div>
                                    </div>
                                    <div v-else class="col-12 b2c-camket-text b2c-des-highlight mt-0">Cam kết gói <b>{{ i.Pack }}
                                            {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ</b> trong
                                        <b>{{ i.ThoiGianCamKet }}</b> tháng</b> <b class="text-line-through">({{ i.Months +
                                            12 }} tháng)</b>
                                        <p style="margin-top:3px" class="text-pack-des" v-html="PackInfo[i.Pack]" />
                                    </div>
                                </div>
                                <td style="font-size:14px;text-align:left;line-height:32px"
                                    v-else-if="$route.query.sim_category_id_sort && $route.query.sim_category_id_sort == 49"
                                    class="b2c-des-highlight">
                                    Gói <b>{{ i.Pack }}</b> - <span v-html="PromotionInfo[i.Pack].flow"></span>
                                    <p style="font-size:12px;margin-top:10px" v-html="PromotionInfo[i.Pack].extraText" />
                                </td>
                                <td style="font-size:14px;text-align:left;line-height:32px" class="b2c-des-highlight"
                                    v-else>Được đăng ký Gói cước
                                    <b>{{ i.Pack }}
                                        {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ </b>siêu ưu đãi
                                    <p style="font-size:12px;margin-top:5px" v-html="PackInfo[i.Pack]" />
                                </td>
                                <div class="row badge-mobile mt-1">
                                    <div class="col-8 d-flex align-items-center">
                                        <span class="badge badge-primary b2c-badge cursor-pointer mr-1"
                                            style="padding: 5px 5px;" @click="handleTypeNumber(i.SimType)">{{ i.group_name
                                            }}</span>
                                        <span @click="handleTypeTags(item)" v-for="(item, index) of i.Tags" :key="index"
                                            class="badge badge-primary b2c-badge cursor-pointer mt-1"
                                            style="padding: 5px 5px;" :class="index !== i.Tags ? 'mr-1' : ''">{{ item.Tag
                                            }}</span>
                                        <span class="badge badge-primary b2c-badge mt-1" @click="handlePledge()"
                                            style="padding: 5px 5px;" v-if="i.ThoiGianCamKet > 0">Số cam kết</span>
                                        <span class="badge badge-primary b2c-badge mt-1" @click="handleSimVip()"
                                            style="padding: 5px 5px;" v-if="i.Price > 5000000">SỐ VIP</span>
                                    </div>
                                    <div class="col-4 text-right" v-if="cart.find(j => j.Phone == i.Phone) == undefined">
                                        <button v-if="cart.find(j => j.Phone == i.Phone) == undefined && !isHasSearchCode"
                                            @click="addToCart(i)" class="b2c-buy-btn">Mua ngay
                                        </button>
                                        <button v-if="cart.find(j => j.Phone == i.Phone) == undefined && isHasSearchCode"
                                            @click="addToCart(i)" class="b2c-buy-btn">Chọn ngay
                                        </button>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-card>
                        <!-- <div class="row">
                            <b-col cols="12" v-if="totalRecords>rows.length">
                            <b-pagination class="float-right pt-1 pb-3"
                                            v-model="serverParams.page"
                                            :total-rows="totalRecords"
                                            :per-page="serverParams.pageSize"
                                            first-number
                                            last-number
                                            @change="w().scrollTo(0,0)"
                            ></b-pagination>
                            </b-col>
                        </div> -->
                        <div class="row">
                            <b-col cols="12" v-if="totalRecords > rows.length">
                                <b-pagination class="float-right pt-1 pb-3" v-model="serverParams.page"
                                    :total-rows="totalRecords" :per-page="serverParams.pageSize" first-number
                                    last-number></b-pagination>
                            </b-col>
                        </div>
                    </div>
                    <div class="text-center d-flex justify-content-center" v-if="isLoading">
                        <b-spinner variant="danger"></b-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const SearchForMonth = () => import('@/components/SearchForMonth.vue');
import { BPagination, BRow, BCol, BCard, BCardText, BSpinner } from "bootstrap-vue"
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment"
import InputRequiredPhone from '@/components/InputRequiredPhone.vue';
import {formatNumber} from "@core/utils/utils";
import CountDown from "@/components/CountDown.vue"

import "swiper/swiper-bundle.css";
import "../assets/css/popup.css";
SwiperCore.use([Navigation]);

export default {
    components: {
        Swiper,
        SwiperSlide,
        SearchForMonth,
        BPagination,
        BRow,
        BCol,
        BCard,
        BCardText,
        CountDown,
        BSpinner,
        InputRequiredPhone
    },
    data() {
        return {
            isHasSearchCode: false,
            isSeachMonth: false,
            dataSearchMonthly: [
                // {
                //     Id: 1,
                //     Name: "300 số đẹp hàng ngày",
                // },
                {
                    Id: 2,
                    Name: "SIM tam hoa",
                    simType: [553]
                },
                {
                    Id: 3,
                    Name: "SIM đuôi số lặp",
                    simType: [554, 557, 558]
                },
                {
                    Id: 4,
                    Name: "SIM tam hoa giữa",
                    simType: [556]
                },
                {
                    Id: 5,
                    Name: "SIM năm sinh",
                    simType: [555]
                },
            ],
            serverParams: {
                columnFilters: {
                    vip: true,
                    normal: true,
                    year: 0,
                    price1: 0,
                    simCategory: 0,
                    except: [],
                    Status: 0,
                    "simGroup": [
                        42,
                        40,
                        38,
                        41,
                        35,
                        33,
                        34,
                        37,
                        43
                    ],
                    "price": [
                        1,
                        2,
                        3,
                        4
                    ],
                    "channelCode": "SIM50K"
                },
                extra: { price_sort: "default" },
                sort: [],
                page: 1,
                pageSize: 10
            },
            isNotFound: false,
            totalRecords: 0,
            prices: [
                { id: "default", text: "Giá mặc định" },
                { id: "random", text: "Giá ngẫu nhiên" },
                { id: "asc", text: "Giá tăng dần" },
                { id: "desc", text: "Giá giảm dần" }
            ],
            PackInfo: {
                MAY: "<b>4GB/ngày</b>, miễn phí gọi nội mạng VinaPhone & iTel",
                ITEL100:
                    "<b>1GB/ngày</b> (tối đa 20GB/tháng), miễn phí 100 phút gọi nội mạng iTel và Vinaphone",
                ITEL149:
                    "<b>3GB/ngày</b> (tối đa 25GB/tháng), miễn phí 1000 phút gọi nội mạng Vinaphone và iTel",
                ITEL199:
                    "<b>5GB/ngày</b> (tối đa 30GB/tháng), miễn phí 1000 phút gọi nội mạng Vinaphone và iTel",
            },
            PromotionInfo: {
                MAY: {
                    flow: "Miễn phí <b>4GB/ngày</b>",
                    extraText: "Miễn phí gọi nội mạng VinaPhone & iTel"
                },
                ITEL100: {
                    flow: "Miễn phí <b>1GB/ngày</b> (tối đa 20GB/tháng)",
                    extraText: "Miễn phí 100 phút gọi nội mạng iTel và Vinaphone"
                },
                ITEL149: {
                    flow: "Miễn phí <b>3GB/ngày</b> (tối đa 25GB/tháng)",
                    extraText: "Miễn phí 1000 phút gọi nội mạng Vinaphone và iTel"
                },
                ITEL199: {
                    flow: "Miễn phí <b>5GB/ngày</b> (tối đa 30GB/tháng)",
                    extraText: "Miễn phí 1000 phút gọi nội mạng Vinaphone và iTel"
                },
            },
            rows: [],
            cart: [],
            DataPackages: [],
            isLoading: false,
            isClick: null,
            soldList: [],
            stickyAnimateClass: "sticky-animate",
            SearchString: "",
            numberExceptCollapse: true,
        }
    },
    created() {
        // this.getSearchMonthly();
        if (this.$route.query && this.$route.query.sim_category_id_sort && this.$route.query.sim_category_id_sort !== 'null') {
            this.serverParams.extra = {
                sim_category_id_sort: Number(this.$route.query.sim_category_id_sort),
                price_sort: "desc"
            }
        }
        if (this.$route.query.simCategory) {
            this.serverParams.columnFilters.simCategory = Number(this.$route.query.simCategory)
        }
    },
    watch: {
        $route(to, from) {
            this.cart = [];
        },
        serverParams: {
            handler: function (newValue) {
                console.log("run param")
                this.search();
            },
            deep: true,
        },
    },
    async mounted() {
        await this.getSoldTotal();
        await this.getInitData();
        if (this.$route.query.cart) {
            this.cart = JSON.parse(this.$route.query.cart);
        }
        this.search();

        document.addEventListener("scroll", this.handleDocumentScrollEvent)
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.handleDocumentScrollEvent)
    },
    methods: {
        formatNumber(x) {
            return formatNumber(x);
        },
        handleDocumentScrollEvent() {
            const header = document.querySelector('header')
            let headerHeight = 0;
            if (header) {
                headerHeight = header.getBoundingClientRect().height;
            }

            //PC
            if (window.innerWidth > 1100) {
                const pcHeaderElm = document.querySelector('#headerSticky');
                if (pcHeaderElm && !pcHeaderElm.classList.contains(this.stickyAnimateClass)) {
                    const rect = pcHeaderElm.getBoundingClientRect();
                    if (rect.y <= headerHeight + 5) {
                        pcHeaderElm.classList.add(this.stickyAnimateClass)
                    }
                } else if (pcHeaderElm && pcHeaderElm.classList.contains(this.stickyAnimateClass)) {
                    const rect = pcHeaderElm.getBoundingClientRect();
                    if (rect.y > headerHeight + 5) {
                        pcHeaderElm.classList.remove(this.stickyAnimateClass)
                    }
                }
                const listElmPc = document.querySelector('#b2cPcList');
                if (pcHeaderElm && listElmPc) {
                    const listRect = listElmPc.getBoundingClientRect()
                    // if(listRect.y + listRect.height < window.innerHeight + 50){
                    //     this.loadData();
                    // }
                }
            } else {
                const pcHeaderElm = document.querySelector('#headerStickySp');
                if (pcHeaderElm && !pcHeaderElm.classList.contains(this.stickyAnimateClass)) {
                    const rect = pcHeaderElm.getBoundingClientRect();
                    if (rect.y <= headerHeight + 5) {
                        pcHeaderElm.classList.add(this.stickyAnimateClass)
                    }
                } else if (pcHeaderElm && pcHeaderElm.classList.contains(this.stickyAnimateClass)) {
                    const rect = pcHeaderElm.getBoundingClientRect();
                    if (rect.y > headerHeight + 5) {
                        pcHeaderElm.classList.remove(this.stickyAnimateClass)
                    }
                }
                const listElmSp = document.querySelector('#b2cSpList');
                if (pcHeaderElm && listElmSp) {
                    const listRect = listElmSp.getBoundingClientRect()
                    // if(listRect.y + listRect.height < window.innerHeight + 50){
                    //     this.loadData();
                    // }
                }
            }

        },
        scrollToTop() {
            const bannerSim = document.querySelector('#bannerSim');
            let bannerHeight = 0;
            if (bannerSim) {
                bannerHeight = bannerSim.getBoundingClientRect().height;
            }
            window.scrollTo(0, bannerHeight)
        },
        loadData() {
            if (this.totalRecords > this.serverParams.page * this.serverParams.pageSize && !this.isLoading) {
                this.serverParams.page = this.serverParams.page + 1;
            }
        },
        async getSoldTotal() {
            this.loading(true);
            await useJwt
                .get("sim50k/get-list-sold")
                .then((response) => {
                    if (response && response.data && response.data.result) {
                        this.soldList = response.data.result;
                    }
                    else {
                        this.soldList = [];
                    }
                    this.loading(false);
                })
                .catch((err) => {
                    this.loading(false);
                    this.$toast.error(
                        "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                        { icon: true, closeButton: "button" }
                    );
                });
        },
        w() {
            return window;
        },
        addToCart(i) {

            this.$router.push({
                path: '/mua-hang/buoc/2',
                query: { currentPhone: i.Phone, cart: JSON.stringify([...this.cart, { ...i, isBeautifulSimNumber: true }]), cart_info: JSON.stringify(this.cart_info) }
            });
        },
        async getInitData() {
            this.loading(true);
            await useJwt
                .post("getMasterData/DataPack1", {
                    columnFilters: {},
                    sort: [],
                    page: 1,
                    pageSize: 1000,
                    lang: this.$l,
                })
                .then((response) => {
                    this.DataPackages = response.data.result;
                    this.loading(false);
                })
                .catch((err) => {
                    this.loading(false);
                    this.$toast.error(
                        "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                        { icon: true, closeButton: "button" }
                    );
                });
        },
        // getSearchMonthly() {
        //     const params = {
        //         "columnFilters": {
        //             "IsManySearchMonthly": 1
        //         },
        //         "sort": [],
        //         "page": 1,
        //         "pageSize": 10
        //     }
        //     useJwt.post('getMasterData/SimCategory', params)
        //     .then((response) => {
        //         this.dataSearchMonthly = response.data.result
        //     }).catch((error) => {
        //         this.$toast({
        //             component: ToastificationContent,
        //             props: {
        //                 title: "Lỗi trong quá trình xứ lý",
        //                 icon: "AlertTriangleIcon",
        //                 variant: "danger",
        //             },
        //         });
        //     });
        // },
        handlesearch(id, types) {
            this.isClick = id;
            if (!!types) {
                this.serverParams.columnFilters = {
                    ...this.serverParams.columnFilters,
                    simType: types
                }
                this.serverParams.page = 1
            } else {
                this.serverParams.columnFilters = {
                    ...this.serverParams.columnFilters,
                    simType: []
                }
                this.serverParams.page = 1
            }
        },
        handlePledge() {
            delete this.serverParams.columnFilters.simTagGroup;
            delete this.serverParams.columnFilters.simTag;
            delete this.serverParams.columnFilters.isVip;
            this.serverParams.columnFilters.isSoCamKet = true;
            this.serverParams.page = 1;
            this.search();
        },
        handleSimVip() {
            delete this.serverParams.columnFilters.simTagGroup;
            delete this.serverParams.columnFilters.simTag;
            delete this.serverParams.columnFilters.isSoCamKet;
            this.serverParams.columnFilters.isVip = true;
            this.serverParams.page = 1;
            this.search();
        },
        handleTypeTags(item) {
            delete this.serverParams.columnFilters.simTagGroup;
            delete this.serverParams.columnFilters.isSoCamKet;
            delete this.serverParams.columnFilters.isVip;
            this.serverParams.columnFilters.simTag = item.Tag;
            this.serverParams.page = 1
            this.search();
        },
        handleTypeNumber(simType) {
            delete this.serverParams.columnFilters.simTag;
            delete this.serverParams.columnFilters.isSoCamKet;
            delete this.serverParams.columnFilters.isVip;
            this.serverParams.columnFilters.simTagGroup = simType;
            this.serverParams.page = 1
            this.search();
        },
        search() {
            this.isLoading = true;
            this.loading(true);
            // if(this.serverParams.page == 1){
            //     this.loading(true);
            //     this.scrollToTop();
            // }
            this.serverParams.columnFilters.price1 = this.serverParams.columnFilters.price1 * 1;
            useJwt
                .post("sim_search", this.serverParams)
                .then((response) => {

                    if (response.data.totalRecords > 0) {
                        this.rows = response.data.result;
                        this.totalRecords = response.data.totalRecords;
                        this.isNotFound = response.data.extra.isMatch == 0 ? true : false;
                    } else {
                        this.isNotFound = response.data.extra.isMatch == 0 ? true : false;
                    }

                    this.w().scrollTo(0, 550);

                    this.loading(false);
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.loading(false);
                    this.isLoading = false;
                    this.$toast.error(
                        "Có lỗi trong khi tìm kiếm số, Quý khách vui lòng thử lại!",
                        { icon: true, closeButton: "button" }
                    );
                    this.isNotFound = true;
                });

        },
        handleSearch() {
            this.serverParams.columnFilters.search = this.SearchString;
            this.serverParams.page = 1;
            this.search();
        },
        exception(value) {
            this.serverParams.page = 1;
            var e = this.serverParams.columnFilters.except.find((i) => i == value);
            if (e != undefined) {
                this.serverParams.columnFilters.except =
                    this.serverParams.columnFilters.except.filter((i) => i != value);
            } else {
                this.serverParams.columnFilters.except.push(value);
            }
        },
    },
    computed: {
        soldTotal() {
            return this.soldList.reduce((prev, cur) => {
                return prev + cur.sold;
            }, 0)
        },
        soldPercent() {
            return (this.soldTotal / 300 * 100).toFixed(0)
        }
    }
}
</script>
<style lang="scss" scoped>
.b2c-number-except-colapse {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
    width: 28px;
    height: 28px;
    margin: 9px 16px 0px;
    float: right;
}

.b2c-number-except-nocolapse {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
    width: 28px;
    height: 28px;
    margin: 9px 16px 0px;
    float: right;
}

.b2c-pc-number-except {
    height: 46px;
    background: #d71a1f;
    width: calc(100% - 50px);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: flex;
    vertical-align: middle;
    margin-top: 0px;
    margin-right: 30px;
    margin-left: 15px;
}

.b2c-pc-number-except-e {
    margin-right: 30px;
    margin-left: 15px;
    height: auto;
    background: #d71a1f;
    width: calc(100% - 50px);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: flex;
    vertical-align: middle;
    margin-top: 0px;
}

.b2c-pc-number-except-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 46px;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 8px;
    white-space: nowrap;
}

.b2c-pc-number-except-buttons {
    padding: 0px;
    min-height: 46px;
    overflow: hidden;
}

.b2c-pc-number-except-btn {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 8px 8px 8px 0px;
    color: #fff;
    width: 29px;
    height: 29px;
}

.b2c-pc-number-except-btn-checked {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 8px 8px 8px 0px;
    color: #ed1f24;
    width: 29px;
    height: 29px;
}

.b2c-pc-number-except-colapse {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
    width: 28px;
    height: 28px;
    margin: 9px 16px 0px;
    float: right;
}

.b2c-pc-number-except-nocolapse {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
    width: 28px;
    height: 28px;
    margin: 9px 16px 0px;
    float: right;
}

.b2c-number-except-btn {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 8px 8px 8px 0px;
    color: #fff;
    width: 29px;
    height: 29px;
}

.b2c-number-except-btn-checked {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 8px 8px 8px 0px;
    color: #ed1f24;
    width: 29px;
    height: 29px;
}

.b2c-number-btn {
    width: auto;
    padding-right: 25px;
}

.b2c-number-btn button {
    border: 1px solid #fff;
    width: 49px;
    height: 45px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center;
}

.b2c-pc-find {
    max-width: 122.4rem;
    height: 120px;
    background: #ED1F24;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 34px 0px 20px 0px;
}

.b2c-pc-find .leading-required-number {
    padding-left: 108px;
}

.b2c-pc-number-087 {
    width: 25%;
    text-align: right;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 36px;
    color: #fff;
    padding-top: 3px;
    margin-left: 20px;
}

.b2c-pc-number-msisdn {
    width: 75%;
    padding: 0px 10px;
}

.b2c-pc-find>p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 8px 0px 8px 20px;
}

.b2c-pc-find>div {
    display: flex;
}

.b2c-pc-find>div>div {
    width: 33.3%;
    display: flex;
}

.b2c-pc-find-sub {
    margin-right: auto;
    margin-left: auto;
    max-width: 122.4rem;
}

.b2c-pc-find-sub select {
    width: auto;
}

.b2c-pc-find-sub .b2c-pagination-title-sort {
    margin-left: auto;
}

.most-find-on-month-sp {
    // color: white !important;
}

.justify-content-space-between {
    justify-content: space-between;
}

.bg-change {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-image: url('../assets/images/sim-so-dep/bg1.png');
    background-size: 100% 100%;
}

.fs-sp {
    .text-pack-des {
        color: #3D3D3D;
        font-family: "Roboto";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 19.53px;
        p {
            color: #3D3D3D;
        }
    }
}

.search_for_month {
    height: 42px !important;
    border-radius: 45px !important;
    // border-color: #FFF !important;
    // color: #FFF !important;

    // &:hover {
    //     background-color: rgba(255, 11, 56, 0.889) !important;
    // }

    // &.red-bg {
    //     background-color: #fff !important;
    //     color: #EA0029 !important;
    // }
}

.b2c-buy-btn {
    min-width: 120px;
    height: 38px;
    font-weight: 700;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    padding-inline: 15px !important;
}

.gap-11 {
    gap: 15px;
}

.search__item {
    gap: 10px !important;
}

.search {
    h5 {
        color: #424242;
        font-weight: 600;
        font-size: 20px !important;
    }

    max-width: 122.4rem;
    margin: 0 auto;

    &__item {
        display: flex;
        // justify-content: center;
        gap: 6px;
        flex-wrap: wrap;
    }

    &__item button:hover {
        background-color: red;
        color: white;
        transition: all 0.5s ease;
    }
}

.cs-desc {
    color: #3c3c3c;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    .bold-text {
        color: #3c3c3c;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
    }
}

.cs-desc_sub {
    margin-top: 5px;
    color: #3c3c3c;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 1099px) {
    .search {
        display: block !important;
    }

    h5 {
        margin-bottom: 15px;
    }

    .search__item {
        flex-wrap: nowrap;
        justify-content: start !important;
        overflow-x: auto;
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 600px) {

    .b2c-pc-find {
        border-radius: 0;
        height: max-content;
        padding-bottom: 10px;
        margin-top: 0;

        >div>div {
            width: 100%
        }

        .mb-find {
            display: flex;
            flex-direction: column;
            height: max-content !important;
            padding-inline: 15px;

            .b2c-pc-number-except-e {
                margin: 0 !important;
            }

            .container-087 {
                width: 100%;
                margin-bottom: 15px;
            }

            .b2c-pc-number-except {
                width: 100%;
                margin: 0 !important;
            }
        }
    }

    .search_for_month {}

    .search {
        h5 {
            font-size: 16px !important;
        }

        &_item {
            gap: 6px;
        }
    }

    .b2c-buy-btn {
        width: 107px !important;
        max-width: 107px !important;
        min-width: 107px !important;
        height: 34px;
        font-size: 12px;
    }

    .most-find-on-month-sp {
        padding: 0 20px;
        // color: white !important;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-mt-32 {
        margin-top: 32px;
    }

    .mobile-flex {
        display: flex;
        align-items: end;
    }

    .price-text-mobile .b2c-ghtk-fee {
        line-height: 30px !important;
        font-size: 14px !important;
        margin-left: 5px !important;
        font-weight: 500 !important;
        color: black !important;
    }

    .price-text-mobile .b2c-price-text {
        padding-top: 0px !important;
    }

    .badge-mobile .badge {
        margin-top: 0 !important;
        align-items: center !important;
        padding: 5px 12px;
        color: #FFF !important;
        text-align: center !important;
        font-family: Roboto !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }

    .phone-text-mobile {
        // font-weight: bold !important;
    }
}

.red-bg__important {
    // background: rgb(175 0 31)
    background: #F1F1F1;
}

.red-bg {
    background-color: red;
    color: white;
    transition: all 0.5s ease;
}
</style>
<style lang="scss">
html {
    scroll-behavior: smooth;
}

.page-item.active {
    background: none;

    button {
        font-weight: bold !important;
    }
}

.fs-pc .list-sim-container {
    // height: calc(100vh - 9rem);
    display: flex;
    flex-direction: column;

    .header-sticky {
        z-index: 5;
        position: sticky;
        top: 9rem;
        background: #F1F1F1;
        // background: rgb(175 0 31);
        padding-bottom: 28px !important;
    }

    .b2c-pc-list-container {
        flex: auto;
        padding-bottom: 20px;
        overflow: hidden;
        // background-image: url('../assets/images/sim-so-dep/bg1.png');
        // background-size: 100%;
    }
}

.fs-sp .list-sim-container {
    display: flex;
    flex-direction: column;
    // background: rgb(175 0 31) !important;
    background: #F1F1F1;

    .header-sticky {
        z-index: 5;
        position: sticky;
        top: 12.88vw;
        // background: rgb(242, 242, 242);
        // background: rgb(175 0 31) !important;
        background: #F1F1F1 !important;
        padding-bottom: 0px !important;

        .search-for-month-container {
            margin-top: 10px !important;
        }

        .search__item {
            padding-bottom: 0px !important;

            &::-webkit-scrollbar {
                width: 0px;
                border: 0px solid;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: none;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: none;
                border-radius: 0;
            }
        }
    }

    .b2c-sp-list-container {
        margin-bottom: 20px;
        // background-image: url('../assets/images/sim-so-dep/bg1.png');
        // background-size: 100%;
        background: #F1F1F1;
    }
}

.sticky-animate {
    // position: relative;
    animation: sticky-animate 0.5s linear;
}

@keyframes sticky-animate {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.b2c-page-bg {
    background: none;
    // background-image: url('../assets/images/sim-so-dep/bg.png');
    // background-size: 100%;
}

.search-for-month-container {
    margin-top: 32px;
}

.b2c-buy-btn {
    border: 2px solid #ed1f24;
    -webkit-border-radius: 26px;
    border-radius: 26px;
    font-size: 16px;
    color: #ed1f24;
    height: 34px;
    width: 96px;
}

.b2c-pc-find-sub {
    margin-right: auto;
    margin-left: auto;
    // width: max-content;
    max-width: 122.4rem;
    justify-content: start;

    select {
        width: auto;
    }
}

.b2c-pagination-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    padding-top: 4px;
    color: #424242;
    width: 55%;
}

.b2c-pagination-title-sort {
    margin-left: auto;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    color: #424242;
    background: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    padding: 4px 8px;
    border: none;
    text-align: right;
    width: 45%;
}

.b2c-bg-selected {
    background: #17e0d5;
    line-height: 40px;
}

.b2c-bg-selected .b2c-page-msisdn,
.b2c-bg-selected .b2c-price-text,
.b2c-bg-selected .b2c-camket-text,
.b2c-bg-selected .b2c-price-text b {
    color: #fff;
}

table tr.b2c-bg-selected {
    background: #17e0d5 !important;
}

.b2c-pc-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 122.4rem;
    padding-bottom: 10px;

    table {
        border: 1px solid #c2c2c2;
        width: 100%;
        border-radius: 10px;
        border-style: hidden;
        /* hide standard table (collapsed) border */
        box-shadow: 0 0 0 1px #c2c2c2;
    }

    th {
        font-weight: 700;
        font-size: 18px;
        line-height: 60px;
        height: 60px;
    }

    .b2c-badge-cont {
        width: 50%;
    }

    td {
        font-weight: 500;
        font-size: 16px;
        height: 70px;
        padding: 20px 10px;
        vertical-align: middle;
    }

    td .custom-checkbox {
        margin-left: 80px;
    }

    tr:nth-child(odd) {
        background: #f9f9f9;
    }

    tr:nth-child(even) {
        background: #fff;
    }

    th {
        background: #f9f9f9;
    }

    th:first-child {
        background: #f9f9f9;
        border-top-left-radius: 10px;
    }

    th:last-child {
        background: #f9f9f9;
        border-top-right-radius: 10px;
    }

    tr:last-child:nth-child(odd) td:first-child {
        background: #f9f9f9;
        border-bottom-left-radius: 10px;
    }

    tr:last-child:nth-child(odd) td:last-child {
        background: #f9f9f9;
        border-bottom-right-radius: 10px;
    }
}

.text-line-through {
    color: #3c3c3c !important;
    text-decoration-line: line-through !important;
}

.b2c-ghtk-fee {
    font-size: 14px;
    text-decoration-line: line-through !important;
}

.b2c-phone {
    font-weight: 400;
}

.b2c-phone .spotlight {
    font-weight: 900;
}

.b2c-page-msisdn {
    color: #000;
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 12px;
    letter-spacing: 2px;
}

.b2c-badge {
    font-size: 14px;
}

.b2c-camket-text {
    font-size: 14px;
    margin-top: 12px;
}

.b2c-price-text {
    font-weight: 400;
    font-size: 24px;
    padding-top: 12px;
    letter-spacing: 0.5px;
    color: #424242;
}

.b2c-price-text b {
    // color: #ed1f24;
}

.b2c-des-highlight b {
    // color: #ed1f24;
}

.count-down-container-pc {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 21px;
    background: #FFF;
}

.count-down-container-sp {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    padding: 21px;
    background: #FFF;
    flex-direction: row;
}

.count-down-container-sp .flip-clock {
    display: flex;
}



.count-down-container-sp .progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 40%;
}

.count-down-container-sp .progress-container .trail {
    height: 24px;
}

.count-down-container-sp .progress-container .trail .stroke-container {
    height: 24px;
}

.count-down-container-sp .progress-count-down .trail::after {
    height: 30px !important;
    width: 24px !important;
}

.count-down-container-sp .progress-container .trail .stroke-container .stroke {
    height: 24px;
}

.count-down-container-sp .text-num-of-sold {
    color: #000;
    text-align: center;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 5px;
}

.count-down-container-sp .text-end-on {
    color: #424242;
    text-align: right;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.979px;
    text-transform: uppercase;
    white-space: nowrap;
}

.text-end-on {
    color: #424242;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.979px;
    text-transform: uppercase;
}

.text-num-of-sold {
    color: #424242;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 9.979px;
}

.progress-count-down {
    width: 300px;
    max-width: 90%;

    .trail {
        position: relative;
        height: 32px;
        width: 100%;
        border-radius: 44px;
        background: #D9D9D9;

        &::after {
            content: "";
            position: absolute;
            width: 45px;
            height: 52px;
            left: var(--percent, 0%);
            bottom: 0;
            background-color: transparent;
            background-image: url("../assets/images/sim-so-dep/gif_2.gif");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transform: translateX(-50%);
            transition: all 0.3s linear;
        }

        .stroke-container {
            overflow: hidden;
            width: 100%;
            height: 32px;
            border-radius: 44px;

            .stroke {
                width: var(--percent, 0%);
                height: 32px;
                background: linear-gradient(89deg, #ff0000 -15.86%, #600c1b 201.52%);
                transition: all 0.3s linear;
                position: relative;

                &::after {
                    content: var(--percentText);
                    color: #FFFFFF;
                    font-size: 14px;
                    position: absolute;
                    right: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}</style>
